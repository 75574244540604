<template>
  <div class="trips-view">
    <div class="trips-view__wrapper">
      <Section>
        <HeroTop :backgroundImage="require(`@/assets/images/${image}`)">
          <template slot="hero-top-breadcrumbs">
            <BreadCrumbs :params="{navigation, color: 'rgba(255, 255, 255, .5)'}"/>
          </template>
          <template slot="hero-top-title">
            <h1 class="hero-top__title grid-md-8 grid-lg-8">{{ title }}</h1>
          </template>
          <template slot="hero-top-descriptions">
            <p class="hero-top__description grid-md-8 grid-lg-8">{{ description }}</p>
          </template>
        </HeroTop>
      </Section>
      <Section limiter>
        <Tabs v-if="!$mobile">
          <span class="tabs__tab"
                v-on:click="changeTripType('route,terrenkur')">{{ $t('trips_view.all') }}</span>
          <span class="tabs__tab"
                v-on:click="changeTripType('route')">{{ $t('trips_view.routes') }}</span>
          <span class="tabs__tab"
                v-on:click="changeTripType('terrenkur')">{{ $t('trips_view.terrenkurs') }}</span>
        </Tabs>
        <Sort v-if="!$mobile"
              :type="sortType"
              :view="viewType"
              v-on:change-sort-type="changeSortType"
              v-on:change-view-type="changeViewType"/>
        <transition name="fade">
          <div v-if="viewType === 'cards'">
            <Row class="-m-15"
                 wrap>
              <TripCard :key="$randomKey(item.id)"
                        :params="item"
                        v-for="item in $trips.data"/>
            </Row>
            <Row v-if="$trips.links.next">
              <Button v-on:click.native="nextPage"
                      class="trips-view__more"
                      color="like-link">{{ $t('service.show_more') }}
              </Button>
            </Row>
          </div>
          <component :is="!$laptop ? 'Column' : 'Row'"
                     v-if="viewType === 'map'"
                     justify="between">
            <Column class="grid-md-8 grid-lg-6">
              <List>
                <template slot="list-content">
                  <TripCardOnMap v-for="trip in $trips.data"
                                 :key="trip.id"
                                 v-on:click.native="showTripOnMap(trip)"
                                 :params="trip"/>
                </template>
              </List>
            </Column>
            <Column class="grid-md-8 grid-lg-6 relative">
              <OnMap :location="trip && trip.places[0] && trip.places[0].location || $city.location"
                     :params="{sizeMap: {
                     width: $laptop ? 'calc(50vw - 22px)' : '100%',
                     height},}"
                     :places="trip && trip.places"/>
            </Column>
          </component>
        </transition>
      </Section>
      <Section limiter>
        <Share :url="`${$host}/trips`"
               :title="share.title"
               :description="share.description"/>
      </Section>
      <ModifiedBillboard/>
    </div>
  </div>
</template>

<script>


export default {
  name: 'TripsView',
  data() {
    return {
      trip: null,
      tripType: 'route,terrenkur',
      sortType: 'created_at',
      viewType: 'cards',
    };
  },
  computed: {
    meta() {
      return this?.$meta?.find((item) => item.slug === this.$city.slug);
    },
    image() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.trips?.image
          || this.$meta.find((item) => item.slug === 'default')?.trips?.image;
    },
    title() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.trips?.title
          || this.$meta.find((item) => item.slug === 'default')?.trips?.title;
    },
    description() {
      return this.$meta.find((item) => item.slug === this.$city.slug)?.trips?.description
          || this.$meta.find((item) => item.slug === 'default')?.trips?.title;
    },
    height() {
      let height;
      if (this.$laptop) height = '750px';
      if (this.$tablet) height = '405px';
      if (this.$mobile) height = '300px';
      return height;
    },
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/trips',
        title: this.$i18n.t('menu.routes_and_t'),
      }];
    },
  },
  methods: {
    async showTripOnMap(trip) {
      this.trip = null;
      await this.$http.get(`/api/trips/${trip.id}`)
      // eslint-disable-next-line no-return-assign
        .then((resolve) => this.trip = resolve.data.data);
    },
    changeSortType(type) {
      this.sortType = type;
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: {
          count: 6,
          resolution: 'medium',
          city_id: this.$city.id,
          sort: this.sortType,
          type: this.tripType,
          lang: this.$i18n.locale,
        },
      });
    },
    changeViewType(type) {
      this.viewType = type;
    },
    changeTripType(type) {
      this.tripType = type;
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: {
          count: 6,
          resolution: 'medium',
          city_id: this.$city.id,
          sort: this.sortType,
          type: this.tripType,
          lang: this.$i18n.locale,
        },
      });
    },
    nextPage() {
      this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
        params: {
          count: 6,
          resolution: 'medium',
          city_id: this.$city.id,
          sort: this.sortType,
          type: this.tripType,
          page: this.$trips.links.next.split('?')[1].split('=')[1],
          lang: this.$i18n.locale,
        },
      });
    },
  },
  created() {
    this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 6,
        resolution: 'medium',
        city_id: this.$city.id,
        sort: this.sortType,
        type: this.tripType,
        lang: this.$i18n.locale,
      },
    });
  },
};
</script>
